import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum ConsultationPageQuery {
  all = "all",
}

interface ConsultationStoreState {
  doc: { [key: string]: ConsultationDoc };
  pageQuery: { [key in ConsultationPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
}

interface ConsultationDoc {
  id: string;
  data: ConsultationDocData;
  unsub: Unsubscribe | null;
}

interface ConsultationDocData {
  uid: string;
  versionNo: number;
  lastUpdate: Timestamp | null;

  topic: string;
}

@Module
export default class ConsultationModule
  extends VuexModule
  implements ConsultationStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: ConsultationDoc };
  pageQuery = {} as { [key in ConsultationPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.CONSULTATION_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      versionNo: data.versionNo ?? 0,
      lastUpdate: data.lastUpdate ?? null,

      topic: data.topic ?? "",
    } as ConsultationDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING CONSULTATION DOC`);
    }
  }

  @Mutation
  [Mutations.CONSULTATION_SET_PAGE_QUERY](payload: {
    key: ConsultationPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.CONSULTATION_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.CONSULTATION_POPULATE_PAGE_QUERY](payload: {
    key: ConsultationPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      case ConsultationPageQuery.all:
      default:
        dbQuery = query(
          collection(db, "consultations"),
          where("versionNo", "==", 0),
          orderBy("lastUpdate")
          // limit(50)
        );
        break;
    }

    console.log("waiting");
    getDocs(dbQuery)
      .then((dbDocs) => {
        console.log(
          "done",
          dbDocs.docs.map((e) => e.id)
        );
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(
              doc(db, "consultations", dbDoc.id),
              (dbDoc) => {
                this.context.commit(Mutations.CONSULTATION_SET_DOC, {
                  id: dbDoc.id,
                  data: dbDoc.data(),
                });
              }
            );
            this.context.commit(Mutations.CONSULTATION_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.CONSULTATION_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action
  async [Actions.CONSULTATION_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "consultations"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.CONSULTATION_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "consultations", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.CONSULTATION_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.CONSULTATION_SET_TEXT_QUERY, {
      key: key,
      ids: ids.reverse(),
      append: append,
    });
  }

  @Action({ rawError: true })
  [Actions.CONSULTATION_UPDATE_DOC](payload: {
    id: string;
    consultation: { [x: string]: any };
  }) {
    console.log("RBW_UPDATE_DOC");
    const id = payload.id;
    const consultation = payload.consultation;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const consultationRef = doc(db, "consultations", id);
    batch.update(consultationRef, consultation);

    console.log(id, consultation);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.CONSULTATION_CREATE](payload: {
    profile: { [x: string]: any };
    user: { [x: string]: any };
  }) {
    const profile = payload.profile;
    const user = payload.user;
  }
}

export {
  ConsultationPageQuery,
  ConsultationStoreState,
  ConsultationDoc,
  ConsultationDocData,
};
