export const Mapkey = "AIzaSyCVhaEX85zh-z1GzXGCqLnUjRHU4z8jV9Y";
export const getAddress = async (lat: number, lng: number): Promise<string> => {
  const geocoder = new google.maps.Geocoder();
  const latlng = {
    lat: lat,
    lng: lng,
  };

  const geocode = await geocoder
    .geocode({ location: latlng })
    .catch((e) => window.alert("Geocoder failed due to: " + e));

  return (
    geocode?.results?.[0]?.address_components
      ?.map((e) => {
        const addon = e.types.map((f) => addresTypeToString(f)).join("");
        return `${addon}${e.long_name}`;
      })
      .join(", ") ?? ""
  );
};

export const addresTypeToString = (type: string) => {
  switch (type) {
    case "street_number":
      return "No.";
    // case "street_address":
    //   return "";
    // case "route":
    //   return "";
    // case "intersection":
    //   return "";
    // case "political":
    //   return "";
    // case "country":
    //   return "";
    // case "administrative_area_level_1":
    //   return "";
    // case "administrative_area_level_2":
    //   return "";
    // case "administrative_area_level_3":
    //   return "";
    // case "administrative_area_level_4":
    //   return "";
    // case "administrative_area_level_5":
    //   return "";
    // case "administrative_area_level_6":
    //   return "";
    // case "administrative_area_level_7":
    //   return "";
    // case "colloquial_area":
    //   return "";
    // case "locality":
    //   return "";
    // case "sublocality":
    //   return "";
    // case "neighborhood":
    //   return "";
    // case "premise":
    //   return "";
    // case "subpremise":
    //   return "";
    // case "plus_code":
    //   return "";
    // case "postal_code":
    //   return "";
    // case "natural_feature":
    //   return "";
    // case "airport":
    //   return "";
    // case "park":
    //   return "";
    // case "point_of_interest":
    //   return "";
    // case "":
    //   return type;
    default:
      return "";
  }
};
