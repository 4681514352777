import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum CoursePageQuery {
  all = "all",
}

interface CourseStoreState {
  doc: { [key: string]: CourseDoc };
  pageQuery: { [key in CoursePageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
}

interface CourseDoc {
  id: string;
  data: CourseDocData;
  unsub: Unsubscribe | null;
}

interface CourseDocData {
  vid: string;
  versionNo: number;
  name: string;
  tags: Array<string>;
  description: string;
  dateCreated: Timestamp | null;
  lastUpdated: Timestamp | null;
  thumbnailURL: string;
  videos: {
    dateCreated: Timestamp | null;
    lastUpdated: string;
    title: string;
    description: string;
    videoURL: string;
  };
}

@Module
export default class CourseModule
  extends VuexModule
  implements CourseStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: CourseDoc };
  pageQuery = {} as { [key in CoursePageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.COURSE_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      vid: id,
      versionNo: data.versionNo ?? 0,
      name: data.name ?? "",
      tags: data.tags ?? [],
      description: data.description ?? "",
      dateCreated: data.dateCreated ?? null,
      lastUpdated: data.lastUpdated ?? null,
      thumbnailURL: data.thumbnailURL ?? "",
      videos: {
        dateCreated: data.dateCreated ?? null,
        lastUpdated: data.lastUpdated ?? "",
        title: data.title ?? "",
        description: data.description ?? "",
        videoURL: data.videoURL ?? "",
      },
    } as CourseDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING COURSE DOC`);
    }
  }

  @Mutation
  [Mutations.COURSE_SET_PAGE_QUERY](payload: {
    key: CoursePageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.COURSE_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.COURSE_POPULATE_PAGE_QUERY](payload: {
    key: CoursePageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      case CoursePageQuery.all:
      default:
        dbQuery = query(
          collection(db, "courses"),
          where("versionNo", "==", 0),
          orderBy("lastUpdated"),
          limit(50)
        );
        break;
    }

    console.log("waiting");
    getDocs(dbQuery)
      .then((dbDocs) => {
        console.log(
          "done",
          dbDocs.docs.map((e) => e.id)
        );
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(doc(db, "courses", dbDoc.id), (dbDoc) => {
              this.context.commit(Mutations.COURSE_SET_DOC, {
                id: dbDoc.id,
                data: dbDoc.data(),
              });
            });
            this.context.commit(Mutations.COURSE_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.COURSE_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action
  async [Actions.COURSE_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "courses"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.COURSE_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "courses", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.COURSE_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.COURSE_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action({ rawError: true })
  [Actions.COURSE_UPDATE_DOC](payload: {
    id: string;
    course: { [x: string]: any };
  }) {
    console.log("RBW_UPDATE_DOC");
    const id = payload.id;
    const course = payload.course;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const courseRef = doc(db, "courses", id);
    batch.update(courseRef, course);

    console.log(id, course);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.COURSE_CREATE](payload: {
    profile: { [x: string]: any };
    user: { [x: string]: any };
  }) {
    const profile = payload.profile;
    const user = payload.user;
  }
}

export { CoursePageQuery, CourseStoreState, CourseDoc, CourseDocData };
