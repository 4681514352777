import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum HarvestPageQuery {
  all = "all",
  finished = "finished",
  request = "request",
  receive = "receive",
  delivery = "delivery",
  process = "process",
}

enum HarvestStatus {
  all = "all",
  finished = "finished",
  request = "request",
  receive = "receive",
  delivery = "delivery",
  process = "process",
}

export enum SwallowNestType {
  mangkok1 = "mangkok1",
  mangkok2 = "mangkok2",
  mangkok3 = "mangkok3",
  oval1 = "oval1",
  oval2 = "oval2",
  oval3 = "oval3",
  patahan1 = "patahan1",
  patahan2 = "patahan2",
  patahan3 = "patahan3",
  sudut1 = "sudut1",
  sudut2 = "sudut2",
  sudut3 = "sudut3",
}

interface HarvestStoreState {
  doc: { [key: string]: HarvestDoc };
  pageQuery: { [key in HarvestPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
}

interface HarvestDoc {
  id: string;
  data: HarvestDocData;
  unsub: Unsubscribe | null;
}

interface HarvestDocData {
  hid: string;
  status: HarvestStatus;
  lastUpdate: Timestamp | null;
  farmer: {
    email: string;
    formatedAddress: string;
    geopoint: GeoPoint | null;
    name: string;
    rbwid: string;
    uid: string;
  };
  request: {
    harvestDate: Timestamp | null;
    harvestDetail: {
      [key in SwallowNestType]: {
        imageURL: string;
        weight: number;
      };
    };
    harvestHumidity: number;
    requestDate: Timestamp | null;
    isRejected: boolean;

    note: string;
    dateStart: Timestamp | null;
    dateEnd: Timestamp | null;
  };
  receive: {
    humidity: number;
    price: number;
    isRejected: boolean;

    note: string;
    dateEnd: Timestamp | null;
  };
  delivery: {
    courier: string;
    receiptID: string;
    receiptImageURL: string;
    isFailed: boolean;

    price: number;
    weight: number;
    humidity: number;

    note: string;
    dateStart: Timestamp | null;
    dateEnd: Timestamp | null;
  };
  process: {
    productMarketplace: { [key: string]: number };
    productOthers: { [key in SwallowNestType]: number };
    isFailed: boolean;

    note: string;
    dateStart: Timestamp | null;
    dateEnd: Timestamp | null;
  };
}

const getHarvestStatus = (harvest: HarvestDocData): HarvestStatus => {
  if (harvest?.process?.dateEnd) {
    return HarvestStatus.finished;
  } else if (harvest?.delivery?.dateEnd) {
    return HarvestStatus.process;
  } else if (harvest?.receive?.dateEnd) {
    return HarvestStatus.delivery;
  } else if (harvest?.request?.dateEnd) {
    return HarvestStatus.receive;
  } else {
    return HarvestStatus.request;
  }
};

@Module
export default class HarvestModule
  extends VuexModule
  implements HarvestStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: HarvestDoc };
  pageQuery = {} as { [key in HarvestPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.HARVEST_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      hid: data.hid ?? "",
      status: getHarvestStatus(data as HarvestDocData),
      lastUpdate: data.lastUpdate ?? null,
      farmer: {
        email: data.farmer?.email ?? "",
        formatedAddress: data.farmer?.formatedAddress ?? "",
        geopoint: data.farmer?.geopoint ?? null,
        name: data.farmer?.name ?? "",
        rbwid: data.farmer?.rbwid ?? "",
        uid: data.farmer?.uid ?? "",
      },
      request: {
        harvestDate: data.request?.requestDate ?? null,
        harvestDetail: Object.entries<DocumentData>(
          data.request?.harvestDetail ?? {}
        ).reduce((res, cV) => {
          if (cV[0] in SwallowNestType)
            return {
              ...res,
              [cV[0]]: {
                imageURL: cV[1]?.imageURL ?? "",
                weight: cV[1]?.weight ?? "",
              },
            };
          return res;
        }, {}),
        harvestHumidity: data.request?.harvestHumidity ?? null,
        requestDate: data.request?.requestDate ?? null,
        isRejected: data.request?.isRejected ?? false,

        note: data.request?.note ?? "",
        dateStart: data.request?.dateStart ?? null,
        dateEnd: data.request?.dateEnd ?? null,
      },
      receive: {
        price: data.receive?.price ?? 0,
        humidity: data.receive?.humidity ?? 0,
        isRejected: data.receive?.isRejected ?? false,

        note: data.receive?.note ?? "",
        dateEnd: data.receive?.dateEnd ?? null,
      },
      delivery: {
        courier: data.delivery?.courier ?? "",
        receiptID: data.delivery?.receiptID ?? "",
        receiptImageURL: data.delivery?.receiptImageURL ?? "",

        price: data.delivery?.price ?? 0,
        weight: data.delivery?.weight ?? 0,
        humidity: data.delivery?.humidity ?? 0,
        isFailed: data.delivery?.isFailed ?? false,

        note: data.delivery?.note ?? "",
        dateStart: data.delivery?.dateStart ?? null,
        dateEnd: data.delivery?.dateEnd ?? null,
      },
      process: {
        productMarketplace: data.process?.productMarketplace ?? {},
        productOthers: data.process?.productOthers ?? {},
        isFailed: data.process?.isFailed ?? false,

        note: data.process?.note ?? "",
        dateStart: data.process?.dateStart ?? null,
        dateEnd: data.process?.dateEnd ?? null,
      },
    } as HarvestDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING HARVEST DOC`);
    }
  }

  @Mutation
  [Mutations.HARVEST_SET_PAGE_QUERY](payload: {
    key: HarvestPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.HARVEST_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.HARVEST_POPULATE_PAGE_QUERY](payload: {
    key: HarvestPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      case HarvestPageQuery.request:
        dbQuery = query(
          collection(db, "harvests"),
          where("versionNo", "==", 0),
          where("request.dateStart", "!=", null),
          orderBy("lastUpdate"),
          limit(50)
        );
        break;
      case HarvestPageQuery.receive:
        dbQuery = query(
          collection(db, "harvests"),
          where("versionNo", "==", 0),
          where("request.dateEnd", "!=", null),
          orderBy("lastUpdate"),
          limit(50)
        );
        break;
      case HarvestPageQuery.delivery:
        dbQuery = query(
          collection(db, "harvests"),
          where("versionNo", "==", 0),
          where("receive.dateEnd", "!=", null),
          orderBy("lastUpdate"),
          limit(50)
        );
        break;
      case HarvestPageQuery.process:
        dbQuery = query(
          collection(db, "harvests"),
          where("versionNo", "==", 0),
          where("delivery.dateEnd", "!=", null),
          orderBy("lastUpdate"),
          limit(50)
        );
        break;
      case HarvestPageQuery.finished:
        dbQuery = query(
          collection(db, "harvests"),
          where("versionNo", "==", 0),
          where("process.harvestDate", "!=", null),
          orderBy("lastUpdate"),
          limit(50)
        );
      case HarvestPageQuery.all:
      default:
        dbQuery = query(
          collection(db, "harvests"),
          where("versionNo", "==", 0),
          orderBy("lastUpdate"),
          limit(50)
        );
        break;
    }

    getDocs(dbQuery)
      .then((dbDocs) => {
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(
              doc(db, "harvests", dbDoc.id),
              (dbDoc) => {
                this.context.commit(Mutations.HARVEST_SET_DOC, {
                  id: dbDoc.id,
                  data: dbDoc.data(),
                });
              },
              (error) => {
                console.log("uwu", error);
              }
            );
            this.context.commit(Mutations.HARVEST_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.HARVEST_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action
  async [Actions.HARVEST_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "harvests"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.HARVEST_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "harvests", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.HARVEST_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.HARVEST_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action
  [Actions.HARVEST_UPDATE_DOC](payload: {
    id: string;
    harvest: { [x: string]: any };
  }) {
    const id = payload.id;
    const harvest = payload.harvest;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const harvestRef = doc(db, "harvests", id);
    batch.update(harvestRef, harvest);

    console.log(id, harvest);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.HARVEST_CREATE](payload: {
    profile: { [x: string]: any };
    user: { [x: string]: any };
  }) {
    const profile = payload.profile;
    const user = payload.user;
  }
}

export { HarvestPageQuery, HarvestStoreState, HarvestDoc, HarvestDocData };
