import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

interface AccountData {
  uid: string;
  profile: Profile;
  user: User;
}

interface User {
  email: string;
  registerDate: Timestamp;
  lastOnlineDate: Timestamp | null;
  purchase: {
    total: number;
    lastDate: Timestamp | null;
  };
  sale: {
    total: number;
    lastDate: Timestamp | null;
  };
  status: string;
  location: {
    geopoint: GeoPoint | null;
    address: string;
  };
}

interface Profile {
  imageURL: string | null;
  name: string;
  role: string;
}

enum AccountType {
  appUser = "appUser",
  superteam = "superteam",
  admin = "admin",
}

interface AccountIndex {
  pageUids: Array<Array<string>>;
  queryUids: Array<string>;
  unsubQuery: Unsubscribe | null;
}

interface AccountStoreState {
  doc: { [key: string]: AccountData };
  index: {
    appUser: AccountIndex;
    superteam: AccountIndex;
    admin: AccountIndex;
  };
}

@Module
export default class AccountModule
  extends VuexModule
  implements AccountStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: AccountData };
  index = {
    appUser: {
      pageUids: [] as Array<Array<string>>,
      queryUids: [] as Array<string>,
      unsubQuery: null as Unsubscribe | null,
    },
    superteam: {
      pageUids: [] as Array<Array<string>>,
      queryUids: [] as Array<string>,
      unsubQuery: null as Unsubscribe | null,
    },
    admin: {
      pageUids: [] as Array<Array<string>>,
      queryUids: [] as Array<string>,
      unsubQuery: null as Unsubscribe | null,
    },
  };

  get getUserPageUids(): Array<Array<string>> {
    return this.index.appUser.pageUids;
  }

  @Mutation
  [Mutations.ACCOUNT_SET_USER](payload: { uid: string; user: User }) {
    const uid = payload.uid;
    const user = payload.user;

    if (!this.doc[uid]) {
      this.doc[uid] = {
        uid: uid,
        user: user,
        profile: {
          imageURL: null,
          name: user.email,
          role: "consumer",
        },
      };
    } else {
      this.doc[uid].user = user;
    }
  }
  @Mutation
  [Mutations.ACCOUNT_SET_PROFILE](payload: { uid: string; profile: Profile }) {
    const uid = payload.uid;
    const profile = payload.profile;

    if (!this.doc[uid]) {
      this.doc[uid] = {
        uid: uid,
        profile: profile,
        user: {
          email: "",
          registerDate: Timestamp.fromDate(new Date(1999)),
          lastOnlineDate: null,
          purchase: {
            total: 0,
            lastDate: null,
          },
          sale: {
            total: 0,
            lastDate: null,
          },
          status: "active",
          location: {
            geopoint: null,
            address: "",
          },
        },
      };
    } else {
      this.doc[uid].profile = profile;
    }
  }
  @Mutation
  [Mutations.ACCOUNT_SET_PAGE_INDEX](payload: {
    type: AccountType;
    idx: number;
    uids: Array<string>;
  }) {
    const type = payload.type;
    const idx = payload.idx;
    const uids = payload.uids;

    if (this.index[type].pageUids.length == idx) {
      // this.index[type].pageUids.push(uids);
      // this.index[type].pageUids = [["0000001"]];
      this.index[type].pageUids = [...this.index[type].pageUids, uids];
    } else if (this.index[type].pageUids.length > idx) {
      this.index[type].pageUids[idx] = uids;
    } else {
      console.error(`FAILED SETTING ACCOUNT PAGE IDX`);
    }
    console.log("OwO");
    console.log(type, this.index[type].pageUids[0]);
  }
  @Mutation
  [Mutations.ACCOUNT_SET_QUERY_INDEX](payload: {
    type: AccountType;
    uids: Array<string>;
    unsubscribe: Unsubscribe | null;
  }) {
    const type = payload.type;
    const uids = payload.uids;
    const unsubscribe = payload.unsubscribe;

    this.index[type].queryUids = uids;
    this.index[type].unsubQuery = unsubscribe;
  }

  @Action
  [Actions.ACCOUNT_GET_SINGLE](payload: { uid: string }) {
    const uid = payload.uid;
    if (!this.doc[uid]) {
      onSnapshot(doc(db, "users", uid), (doc) => {
        const user = doc.data();
        this.context.commit(Mutations.ACCOUNT_SET_USER, {
          uid: uid,
          user: {
            email: user?.email ?? "",
            registerDate:
              user?.registerDate ?? Timestamp.fromDate(new Date(1999)),
            lastOnlineDate: user?.lastOnlineDate ?? null,
            purchase: {
              total: user?.purchase?.total ?? 0,
              lastDate: user?.purchase?.lastDate ?? null,
            },
            sale: {
              total: user?.sale?.total ?? 0,
              lastDate: user?.sale?.lastDate ?? null,
            },
            status: user?.status ?? "active",
            location: {
              geopoint: user?.locatin.geopoint ?? null,
              address: user?.locatin.address ?? "",
            },
          } as User,
        });
      });
      onSnapshot(doc(db, "profiles", uid), (doc) => {
        const profile = doc.data();
        this.context.commit(Mutations.ACCOUNT_SET_PROFILE, {
          uid: uid,
          profile: {
            imageURL: profile?.imageURL ?? null,
            name: profile?.name ?? "",
            role: profile?.role ?? "consumer",
          } as Profile,
        });
      });
    }
  }

  @Action
  [Actions.ACCOUNT_GET_PAGE](payload: { type: AccountType; pageIdx: number }) {
    const type = payload.type;
    const pageIdx = payload.pageIdx;
    if (pageIdx == 0) {
      const userQuery = query(
        collection(db, "users"),
        where("versionNo", "==", 0),
        where("authorization", "==", type),
        orderBy("registerDate"),
        limit(50)
      );
      onSnapshot(userQuery, (userSnapshot) => {
        userSnapshot.docs.forEach((userDoc) => {
          const uid = userDoc.id;

          if (!this.doc[uid]) {
            onSnapshot(doc(db, "profiles", uid), (doc) => {
              const profile = doc.data();
              this.context.commit(Mutations.ACCOUNT_SET_PROFILE, {
                uid: uid,
                profile: {
                  imageURL: profile?.imageURL ?? null,
                  name: profile?.name ?? "",
                  role: profile?.role ?? "consumer",
                } as Profile,
              });
            });
          }

          const user = userDoc.data();
          this.context.commit(Mutations.ACCOUNT_SET_USER, {
            uid: uid,
            user: {
              email: user.email ?? "",
              registerDate:
                user.registerDate ?? Timestamp.fromDate(new Date(1999)),
              lastOnlineDate: user.lastOnlineDate ?? null,
              purchase: {
                total: user.purchase?.total ?? 0,
                lastDate: user.purchase?.lastDate ?? null,
              },
              sale: {
                total: user.sale?.total ?? 0,
                lastDate: user.sale?.lastDate ?? null,
              },
              status: user.status ?? "active",
              location: {
                geopoint: user.location?.geopoint ?? null,
                address: user.location?.address ?? "",
              },
            } as User,
          });
        });

        const uids = userSnapshot.docs.map((userDoc) => userDoc.id);
        this.context.commit(Mutations.ACCOUNT_SET_PAGE_INDEX, {
          type: type,
          idx: pageIdx,
          uids: uids,
        });
      });
    } else if (pageIdx > 0) {
      const userQuery = query(
        collection(db, "users"),
        where("versionNo", "==", 0),
        where("authorization", "==", type),
        orderBy("registerDate"),
        startAfter(this.index[type].pageUids[pageIdx - 1].at(-1)),
        limit(50)
      );
    }
  }

  @Action
  async [Actions.ACCOUNT_UPDATE_DOC](payload: {
    uid: string;
    profile: { [x: string]: any };
    user: { [x: string]: any };
    superteam: { [x: string]: any };
  }) {
    const uid = payload.uid;
    const profile = payload.profile;
    const user = payload.user;
    const superteam = payload.superteam;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const profileRef = doc(db, "profiles", uid);
    batch.update(profileRef, profile);

    // Set user value
    const userRef = doc(db, "users", uid);
    batch.update(userRef, user);

    // Set user value
    const superteamRef = doc(db, "superteams", uid);
    batch.update(superteamRef, superteam);

    // Commit the batch
    await batch.commit();
  }

  @Action
  async [Actions.ACCOUNT_CREATE](payload: {
    profile: { [x: string]: any };
    user: { [x: string]: any };
  }) {
    const profile = payload.profile;
    const user = payload.user;
  }
}

export {
  AccountData,
  User,
  Profile,
  AccountType,
  AccountIndex,
  AccountStoreState,
};
