import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  User,
} from "firebase/auth";
import { auth } from "@/core/services/FirebaseService";

export interface AuthStoreState {
  errorMessage: string | null;
  user: User | null;
}

@Module
export default class AuthModule extends VuexModule implements AuthStoreState {
  errorMessage = null;
  user: User | null = null;

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isAuthenticated(): boolean {
    return this.user !== null;
  }

  @Mutation
  [Mutations.AUTH_SET_ERROR](errorMessage) {
    this.errorMessage = errorMessage;
  }

  @Mutation
  [Mutations.AUTH_SET_USER](user: User) {
    this.user = user;
    this.errorMessage = null;
  }

  @Mutation
  [Mutations.AUTH_PURGE_USER]() {
    this.user = null;
    this.errorMessage = null;
  }

  // @Action
  // [Actions.LOGIN](credentials) {
  //   return ApiService.post("login", credentials)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.AUTH_SET_USER, data);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.AUTH_SET_ERROR, response.data.errors);
  //     });
  // }

  @Action
  async [Actions.AUTH_LOGIN](credentials) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      const user = userCredential.user;
      this.context.commit(Mutations.AUTH_SET_USER, userCredential.user);
      return auth.updateCurrentUser(user);
    } catch (error: any) {
      let errorMessage = "Login gagal";
      switch (error?.code) {
        case "auth/invalid-email":
          errorMessage = "Penulisan email salah";
          break;
        case "auth/user-not-found":
          errorMessage = "Email belum terdaftar";
          break;
        case "auth/wrong-password":
          errorMessage = "Password Salah";
          break;
        default:
          errorMessage = "Login gagal";
          break;
      }
      this.context.commit(Mutations.AUTH_SET_ERROR, errorMessage);
    }
  }

  @Action
  [Actions.AUTH_LOGOUT]() {
    this.context.commit(Mutations.AUTH_PURGE_USER);
  }

  @Action
  [Actions.AUTH_REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.AUTH_SET_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.AUTH_SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.AUTH_FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.AUTH_SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.AUTH_SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.AUTH_VERIFY_USER]() {
    const user = await new Promise<User | null>((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          unsubscribe();
          resolve(user);
        },
        reject
      );
    });

    if (user) {
      this.context.commit(Mutations.AUTH_SET_USER, user);
    } else {
      this.context.commit(Mutations.AUTH_SET_ERROR, "Unauthorized");
      this.context.commit(Mutations.AUTH_PURGE_USER);
    }

    return user;
  }
}
