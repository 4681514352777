import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum SeminarPageQuery {
  all = "all",
}

interface SeminarStoreState {
  doc: { [key: string]: SeminarDoc };
  pageQuery: { [key in SeminarPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
}

interface SeminarDoc {
  id: string;
  data: SeminarDocData;
  unsub: Unsubscribe | null;
}

interface SeminarDocData {
  sid: string;
  versionNo: number;
  title: string;
  description: string;
  imageURL: string;
  price: number;
  hideDate: Timestamp | null;
  date: {
    registerStart: Timestamp | null;
    registerEnd: Timestamp | null;
    eventStart: Timestamp | null;
    eventEnd: Timestamp | null;
  };
  keynoteSpeakers: Array<{
    imageURL: string;
    name: string;
    occupation: string;
  }>;
  location: {
    address: string;
    geopoint: GeoPoint | null;
  };
  onlineLocation: {
    platform: string;
    url: string;
  };
}

@Module
export default class SeminarModule
  extends VuexModule
  implements SeminarStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: SeminarDoc };
  pageQuery = {} as { [key in SeminarPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.SEMINAR_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      sid: id,
      versionNo: data.versionNo ?? 0,
      title: data.title ?? "",
      description: data.description ?? "",
      imageURL: data.imageURL ?? "",
      price: data.price ?? 0,
      hideDate: data.hideDate ?? null,
      date: {
        registerStart: data.date?.registerStart ?? null,
        registerEnd: data.date?.registerEnd ?? null,
        eventStart: data.date?.eventStart ?? null,
        eventEnd: data.date?.eventEnd ?? null,
      },
      keynoteSpeakers:
        data.keynoteSpeakers?.map((e) => {
          return {
            imageURL: e.imageURL ?? "",
            name: e.name ?? "",
            occupation: e.occupation ?? "",
          };
        }) ?? [],
      location: {
        address: data.location.address ?? "",
        geopoint: data.location.geopoint ?? null,
      },
      onlineLocation: {
        platform: data.onlineLocation.platform ?? "",
        url: data.onlineLocation.url ?? "",
      },
    } as SeminarDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING SEMINAR DOC`);
    }
  }

  @Mutation
  [Mutations.SEMINAR_SET_PAGE_QUERY](payload: {
    key: SeminarPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.SEMINAR_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.SEMINAR_POPULATE_PAGE_QUERY](payload: {
    key: SeminarPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      case SeminarPageQuery.all:
      default:
        dbQuery = query(
          collection(db, "seminars"),
          where("versionNo", "==", 0),
          orderBy("date.eventStart")
          // limit(50)
        );
        break;
    }

    console.log("waiting");
    getDocs(dbQuery)
      .then((dbDocs) => {
        console.log(
          "done",
          dbDocs.docs.map((e) => e.id)
        );
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(doc(db, "seminars", dbDoc.id), (dbDoc) => {
              this.context.commit(Mutations.SEMINAR_SET_DOC, {
                id: dbDoc.id,
                data: dbDoc.data(),
              });
            });
            this.context.commit(Mutations.SEMINAR_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.SEMINAR_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action
  async [Actions.SEMINAR_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "seminars"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.SEMINAR_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "seminars", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.SEMINAR_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.SEMINAR_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action({ rawError: true })
  [Actions.SEMINAR_UPDATE_DOC](payload: {
    id: string;
    seminar: { [x: string]: any };
  }) {
    console.log("RBW_UPDATE_DOC");
    const id = payload.id;
    const seminar = payload.seminar;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const seminarRef = doc(db, "seminars", id);
    batch.update(seminarRef, seminar);

    console.log(id, seminar);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.SEMINAR_CREATE](payload: {
    profile: { [x: string]: any };
    user: { [x: string]: any };
  }) {
    const profile = payload.profile;
    const user = payload.user;
  }
}

export { SeminarPageQuery, SeminarStoreState, SeminarDoc, SeminarDocData };
