enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  AUTH_VERIFY_USER = "verifyAuth",
  AUTH_LOGIN = "login",
  AUTH_LOGOUT = "logout",
  AUTH_REGISTER = "register",
  UPDATE_USER = "updateUser",
  AUTH_FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  // account
  ACCOUNT_GET_SINGLE = "getAccountSingle",
  ACCOUNT_GET_PAGE = "getAccountPage",
  ACCOUNT_GET_QUERY = "getAccountQuery",
  ACCOUNT_UPDATE_DOC = "updateAccountData",
  ACCOUNT_CREATE = "createAccount",

  // rbw
  RBW_POPULATE_PAGE_QUERY = "getRbwPageQuery",
  RBW_POPULATE_TEXT_QUERY = "getRbwTextQuery",
  RBW_UPDATE_DOC = "updateRbwDoc",
  RBW_CREATE = "createRbwDoc",

  // harvest
  HARVEST_POPULATE_PAGE_QUERY = "getHarvestPageQuery",
  HARVEST_POPULATE_TEXT_QUERY = "getHarvestTextQuery",
  HARVEST_UPDATE_DOC = "updateHarvestDoc",
  HARVEST_CREATE = "createHarvestDoc",

  // product
  PRODUCT_POPULATE_PAGE_QUERY = "getProductPageQuery",
  PRODUCT_POPULATE_TEXT_QUERY = "getProductTextQuery",
  PRODUCT_UPDATE_DOC = "updateProductDoc",
  PRODUCT_CREATE = "createProductDoc",

  // seminar
  SEMINAR_POPULATE_PAGE_QUERY = "getSeminarPageQuery",
  SEMINAR_POPULATE_TEXT_QUERY = "getSeminarTextQuery",
  SEMINAR_UPDATE_DOC = "updateSeminarDoc",
  SEMINAR_CREATE = "createSeminarDoc",

  // participant
  PARTICIPANT_POPULATE_PAGE_QUERY = "getParticipantPageQuery",
  PARTICIPANT_UPDATE_DOC = "updateParticipantDoc",
  PARTICIPANT_CREATE = "createParticipantDoc",

  // consultation
  CONSULTATION_POPULATE_PAGE_QUERY = "getConsultationPageQuery",
  CONSULTATION_POPULATE_TEXT_QUERY = "getConsultationTextQuery",
  CONSULTATION_UPDATE_DOC = "updateConsultationDoc",
  CONSULTATION_CREATE = "createConsultationDoc",

  // chat
  CHAT_POPULATE_PAGE_QUERY = "getChatPageQuery",
  CHAT_UPDATE_DOC = "updateChatDoc",
  CHAT_CREATE = "createChatDoc",

  // transaction
  TRANSACTION_POPULATE_PAGE_QUERY = "getTransactionPageQuery",
  TRANSACTION_POPULATE_TEXT_QUERY = "getTransactionTextQuery",
  TRANSACTION_UPDATE_DOC = "updateTransactionDoc",
  TRANSACTION_CREATE = "createTransactionDoc",

  // article
  ARTICLE_POPULATE_PAGE_QUERY = "getArticlePageQuery",
  ARTICLE_POPULATE_TEXT_QUERY = "getArticleTextQuery",
  ARTICLE_UPDATE_DOC = "updateArticleDoc",
  ARTICLE_CREATE = "createArticleDoc",

  // video
  COURSE_POPULATE_PAGE_QUERY = "getVideoPageQuery",
  COURSE_POPULATE_TEXT_QUERY = "getVideoTextQuery",
  COURSE_UPDATE_DOC = "updateVideoDoc",
  COURSE_CREATE = "createVideoDoc",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  AUTH_PURGE_USER = "logOut",
  AUTH_SET_USER = "setUser",
  AUTH_SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  // account
  ACCOUNT_SET_PROFILE = "setAccountProfille",
  ACCOUNT_SET_USER = "setAccountUser",
  ACCOUNT_SET_PAGE_INDEX = "setAccountPageIndex",
  ACCOUNT_SET_QUERY_INDEX = "setAccountQueryIndex",

  // rbw
  RBW_SET_DOC = "setRbwDoc",
  RBW_SET_PAGE_QUERY = "setRbwPagination",
  RBW_RESET_PAGINATION = "resetRbwPagination",
  RBW_SET_TEXT_QUERY = "setRbwQuery",

  // harvest
  HARVEST_SET_DOC = "setHarvestDoc",
  HARVEST_SET_PAGE_QUERY = "setHarvestPagination",
  HARVEST_RESET_PAGINATION = "resetHarvestPagination",
  HARVEST_SET_TEXT_QUERY = "setHarvestQuery",

  // product
  PRODUCT_SET_DOC = "setProductDoc",
  PRODUCT_SET_PAGE_QUERY = "setProductPagination",
  PRODUCT_RESET_PAGINATION = "resetProductPagination",
  PRODUCT_SET_TEXT_QUERY = "setProductQuery",

  // seminar
  SEMINAR_SET_DOC = "setSeminarDoc",
  SEMINAR_SET_PAGE_QUERY = "setSeminarPagination",
  SEMINAR_RESET_PAGINATION = "resetSeminarPagination",
  SEMINAR_SET_TEXT_QUERY = "setSeminarQuery",

  // participant
  PARTICIPANT_SET_DOC = "setParticipantDoc",
  PARTICIPANT_SET_PAGE_QUERY = "setParticipantPagination",
  PARTICIPANT_RESET_PAGINATION = "resetParticipantPagination",

  // consultation
  CONSULTATION_SET_DOC = "setConsultationDoc",
  CONSULTATION_SET_PAGE_QUERY = "setConsultationPagination",
  CONSULTATION_RESET_PAGINATION = "resetConsultationPagination",
  CONSULTATION_SET_TEXT_QUERY = "setConsultationQuery",

  // chat
  CHAT_SET_DOC = "setChatDoc",
  CHAT_SET_PAGE_QUERY = "setChatPagination",
  CHAT_RESET_PAGINATION = "resetChatPagination",

  // transaction
  TRANSACTION_SET_DOC = "setTransactionDoc",
  TRANSACTION_SET_PAGE_QUERY = "setTransactionPagination",
  TRANSACTION_RESET_PAGINATION = "resetTransactionPagination",
  TRANSACTION_SET_TEXT_QUERY = "setTransactionQuery",

  // article
  ARTICLE_SET_DOC = "setArticleDoc",
  ARTICLE_SET_PAGE_QUERY = "setArticlePagination",
  ARTICLE_RESET_PAGINATION = "resetArticlePagination",
  ARTICLE_SET_TEXT_QUERY = "setArticleQuery",

  // video
  COURSE_SET_DOC = "setVideoDoc",
  COURSE_SET_PAGE_QUERY = "setVideoPagination",
  COURSE_RESET_PAGINATION = "resetVideoPagination",
  COURSE_SET_TEXT_QUERY = "setVideoQuery",
}

export { Actions, Mutations };
